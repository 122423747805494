import React, { useEffect, useState, useRef } from 'react'
import './App.scss'
import { Form, Input, Image, Button, Toast, Dialog, Result } from 'antd-mobile'
import { myAxios as axios } from '@/https'
import { getParams } from '../../utils'

const wx = window.wx
let appid = 'wx7f5038a0453c09e6' // 正式公众号
if (!window.location.origin.includes('naerdiao.com')) {
  appid = 'wxd20ac100579e7318' // 测试公众号
}

function App(porps) {
  const [fishInfo, setFishInfo] = useState({})
  const [sendDisabled, setSendDisabled] = useState(false)
  const [second, setSecond] = useState(60)
  const codeRef = useRef(null)
  const [form] = Form.useForm()
  const [competitionId, setCompetitionId] = useState('')
  const [openid, setOpenid] = useState(null)
  const [signSuccess, setSignSuccess] = useState(false)
  useEffect(() => {
    const competitionId = getParams().id
    setCompetitionId(competitionId)
    const openid = localStorage.getItem('openId')
    if(openid){
      getCompetitionInfo(competitionId)
      setOpenid((openid))
    }else {
      const code = getParams().code
      if(!code){
        //不存在就打开上面的地址进行授权
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(window.location.href)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
      }else {
        getCompetitionInfo(competitionId)
        getUserOpenId(code)
      }
    }

  }, [])
  // 获取竞赛详情
  const getCompetitionInfo = async (competitionId) => {
    const { data } = await axios({
      method: 'get',
      url: '/competition/getById',
      params: { id: competitionId }
    })
    if (data.code === '00000' && data.data) {
      const info = data.data
      setFishInfo(info)
      document.title = info.name
      console.log('data', data)

      const shareData = {
        title:data.data.name,
        desc: data.data.description,//这里请特别注意是要去除html
        link:window.location.origin + window.location.pathname + '?id=' + competitionId,
        imgUrl: data.data.coverUrl,
        success:function (){
          console.log('设置成功')
        }
      };

      shareFriends(shareData)

    } else {
      Toast.show({
        content: '活动不存在'
      })
    }
  }

  // 获取openid
  const getUserOpenId = async (code) => {
    const { data } = await axios({
      method: 'post',
      url: '/wechat/getOpenidByH5',
      data: { code }
    })
    let openId = data.data
    // openId = 'ofgMa6-_JierrwHdYJ6g7BQVKPew'
    console.log('openId', data.data)
    localStorage.setItem('openId',openId)
    // window.history.back()
    setOpenid(openId)
  }

  // 参与竞赛
  const handleUserPartCompetition = async (values) => {
    const { userName, cardId, phone } = values
    Toast.show({
      icon: 'loading',
      duration: 0,
      content: '加载中…'
    })
    const { data } = await axios({
      method: 'post',
      url: '/competition/joinCompetition',
      data: {
        userName,
        cardId,
        phone,
        openid,
        competitionId: parseInt(competitionId)
      }
    })
    console.log('参与竞赛下单', data)
    if (data.code !== '00000') {
      Toast.show({
        content: data.message
      })
      return
    }
    // 如果是免费 直接报名成功
    if (fishInfo.price === 0) {
      setSignSuccess(true)
      Toast.clear()
      return
    }

    function onBridgeReady() {
      window.WeixinJSBridge.invoke(
        'getBrandWCPayRequest', {
          appId: data.data.package.appid,     //公众号名称，由商户传入
          timeStamp: data.data.timeStamp,         //时间戳，自1970年以来的秒数
          nonceStr: data.data.nonceStr, //随机串
          package: 'prepay_id=' + data.data.package.prepay_id,
          signType: 'MD5',
          paySign: data.data.paySign //微信签名
        },
        function (res) {
          if (res.err_msg === 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回    ok，但并不保证它绝对可靠。
            handleQueryPayStatus(data.data.outTradeNo)
          } else {
            // 取消订单
            handleCancelOrder(data.data.outTradeNo)
          }
        }
      )
    }

    if (typeof WeixinJSBridge === 'undefined') {
      if (document.addEventListener) {
        document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false)
      } else if (document.attachEvent) {
        document.attachEvent('WeixinJSBridgeReady', onBridgeReady)
        document.attachEvent('onWeixinJSBridgeReady', onBridgeReady)
      }
    } else {
      onBridgeReady()
    }

  }
  // 查询支付状态
  const handleQueryPayStatus = async (outTradeNo) => {
    Toast.show({
      icon: 'loading',
      duration: 0,
      content: '加载中…'
    })
    const { data } = await axios({
      method: 'get',
      url: '/competition/getByoutTradeNoFlag',
      params: {
        openid,
        outTradeNo
      }
    })
    Toast.clear()
    console.log('支付状态', data)
    if (data.code === '00000' && data.data) {
      setSignSuccess(true)
    }

  }
  // 取消订单
  const handleCancelOrder = async (outTradeNo) => {

    const { data } = await axios({
      method: 'get',
      url: '/competition/cancelOrder',
      params: {
        openid,
        outTradeNo
      }
    })
    Toast.clear()
    console.log('取消订单', data)

  }

  // 提交
  const onFinish = async (values) => {
    console.log('提交', values, this)
    if (window.submitLoaing) return
    window.submitLoaing = true
    const yzmFlag = await checkYZM({ code: values.code, phone: values.phone })
    window.submitLoaing = false
    // 验证码 校验通过时
    if (yzmFlag) {
      handleUserPartCompetition(values)
    }

  }

  // 获取验证码
  const getYZM = async () => {
    const values = await form.validateFields(['phone'])
    if (values.phone) {
      // 获取验证码
      setSendDisabled(true)
      timekeeping()
      axios({
        method: 'post',
        url: '/authorize/smsToUser',
        data: {
          phoneNumber: values.phone,
          type: 'verifyCode'
        }
      })
    }
  }
  // 校验验证码
  const checkYZM = async (data) => {
    return new Promise(resolve => {
      axios({
        method: 'post',
        url: '/user/codeFlag',
        data
      }).then((res) => {
        console.log(res)
        if (res.data.code === '00000') {
          resolve(true)
        } else {
          Toast.show({
            content: res.data.message
          })
          resolve(false)
        }
      }).catch(() => {
        resolve(false)
      })
    })
  }
  // 发送验证码倒计时
  const timekeeping = () => {
    setSecond(60)
    let sCode = 60
    let codeTime = setInterval(() => {
      sCode--
      codeRef.current.innerHTML = sCode + 's重新发送'
      if (sCode === 0) {
        clearInterval(codeTime)
        codeRef.current.innerHTML = '发送验证码'
        setSendDisabled(false)
      }
    }, 1000)
  }
  // 查看赛程
  const handleLookSchedule = (e) => {
    e.stopPropagation()
    Dialog.alert({
      title: '赛程',
      content: (<div style={{ whiteSpace: 'pre-wrap' }}>{fishInfo.description}</div>)
    })
  }

  // 分享好友
  const shareFriends = (shareData) => {
    axios({
      method: 'get',
      url: '/wechat/getWeixinSign',
      params: {
        url: encodeURIComponent(window.location.href.split('#')[0])
      }
    }).then(res => {
      if (res.data.code === '00000') {
        if (wx) {
          wx.config({
            debug: false,
            appId: 'wx7f5038a0453c09e6',// 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp,// 必填，生成签名的时间戳
            nonceStr: res.data.data.noncestr,// 必填，生成签名的随机串
            signature: res.data.data.sign,// 必填，签名，见附录1
            jsApiList: [
              'onMenuShareAppMessage', //1.0 分享到朋友
              'onMenuShareTimeline', //1.0分享到朋友圈
              'updateAppMessageShareData', //1.6 分享到朋友
              'updateTimelineShareData' //1.6分享到朋友圈
            ] // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
          })

          wx.ready(() => {
            if (wx.onMenuShareAppMessage) { //微信文档中提到这两个接口即将弃用，故判断
              wx.onMenuShareAppMessage(shareData)//1.0 分享到朋友
              wx.onMenuShareTimeline(shareData)//1.0分享到朋友圈
            } else {
              wx.updateAppMessageShareData(shareData)//1.4 分享到朋友
              wx.updateTimelineShareData(shareData)//1.4分享到朋友圈
            }
          })

        }

      }
    })

  }
  // 继续报名
  const handleContinueSgin = () => {
    form.resetFields()
    setSignSuccess(false)
  }

  return (
    <div className="pay_container">
      {
        // (code && fishInfo.id) && <>
        (fishInfo.id && !signSuccess) && <>
          <Image src={fishInfo.coverUrl}/>
          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            // initialValues={{
            //   userName: '陈大神',
            //   phone: '17603057323',
            //   cardId: '111111111111111111',
            //   code: '112233'
            // }}
            footer={
              <Button block type="submit" color="primary" size="large">
                立即报名
              </Button>
            }
          >
            <Form.Item
              label="活动名称"
              extra={<a onClick={handleLookSchedule}>查看赛程</a>}
            ><span>{fishInfo.name}</span></Form.Item>
            <Form.Item label="举办地点"><span>{fishInfo.address}</span></Form.Item>
            <Form.Item label="报名费用"><span>{fishInfo.price ? fishInfo.price + '元' : '免费报名'}</span></Form.Item>
            <Form.Item
              name="userName"
              label="姓名"
              rules={[{ required: true, message: '姓名不能为空' }]}
            >
              <Input onChange={console.log} autoComplete="off" placeholder="请输入姓名" maxLength={10}/>
            </Form.Item>
            <Form.Item
              name="cardId"
              label="身份证号码"
              rules={[{ required: true, pattern: /^[0-9]{18}$/, message: '请输入正确的身份证号码' }]}
            >
              <Input onChange={console.log} autoComplete="off" placeholder="请输入身份证号码" maxLength={18}/>
            </Form.Item>
            <Form.Item
              name="phone"
              label="手机号"
              rules={[{ required: true, pattern: /^1[0-9]{10}$/, message: '请输入正确的手机号码' }]}
            >
              <Input onChange={console.log} autoComplete="off" placeholder="请输入手机号" maxLength={11}/>
            </Form.Item>
            <Form.Item
              label="短信验证码"
              name="code"
              rules={[{ required: true, message: '验证码不能为空' }]}
              extra={<Button color="primary" disabled={sendDisabled} fill="none" onClick={getYZM}>
                <span ref={codeRef}>发送验证码</span>
              </Button>}
            >
              <Input placeholder="请输入验证码" autoComplete="off" maxLength={6}/>
            </Form.Item>
          </Form>

        </>
      }
      {
        signSuccess && <>
          <Result
            status="success"
            title="报名成功"
          />
          {/*description="恭喜您已报名成功 哪儿钓第一届水友赛区。"*/}
          <Button color="primary" block style={{ width: '90%', margin: '0 auto' }} onClick={handleContinueSgin}>继续报名</Button>
        </>
      }

    </div>
  )
}

export default App
