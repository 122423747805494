// 工具类

/**
 * 获取url参数
 * @returns {{}}
 */
export function getParams() {
  let url = decodeURI(window.location.search)
  let params = {}

  if (url.indexOf('?') !== -1) {
    let urlStr = url.substring(1)
    let urlParamsList = urlStr.split('&')

    urlParamsList.map((item) => {
      let [key, value] = item.split('=')
      params[key] = value
    })
  }

  return params
}
