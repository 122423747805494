import md5 from 'js-md5'
import JSEncrypt from 'jsencrypt'

const randomString = () => {
  const len = 10
  const allString = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
  const maxPos = allString.length;
  let pwd = '';
  for (let i = 0; i < len; i++) {
    pwd += allString.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd
}

const sortAscii = (obj) => {
  let arr = [];
  let num = 0;
  for (let i in obj) {
    arr[num] = i;
    num++;
  }
  let sortArr = arr.sort();
  //let sortObj = {};    //完成排序值
  let str = ''; //自定义排序字符串
  for (let i in sortArr) {
    str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
  }
  //去除两侧字符串
  let char = '&'
  str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');

  return str;
}

const encryptMdRsa = (param) => {
  const timestamp = new Date().getTime()
  const uuid = randomString()
  let option = {}
  if (param) {
    let params = {}
    for (var key in param) {
      if(param[key] !== "" && param[key] !== null && param[key] !== undefined){
        params[key] = param[key]
      }
    }
    option = {
      ...params,
      timestamp,
      uuid
    }
  } else {
    option = {
      timestamp,
      uuid
    }
  }
  option = sortAscii(option)
  const encryptStr = option + '&appKey=b26f4832cc4230c9c81a713cdf2951fb'
  const encryptMd5 = md5(encryptStr);

  let encrypt = new JSEncrypt();
  const publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCQ4Pmql8RCMwdd1kqojtlIs4oXgVSI9AruiId/5xEVNKflBpqcynNAwNZ9lQIKqpzwo22mpiESQDEt7khlqjio2TdNdnd3Mt4BO1Cnv2YsA5m7LRzkRYf83FN1YL8M/H67FkeuIvIxKaYZNXO7POw88MNDb8/IsClqgRch2s3q9wIDAQAB'
  encrypt.setPublicKey(publicKey);
  const sign = encrypt.encrypt(encryptMd5);

  return {
    sign,
    uuid,
    timestamp
  }
}

export default encryptMdRsa